<template>
  <Page :hasBg="false">
    <!-- 导航栏 -->
    <NavBar :title="`${infoText.name}详情`" @clickLeft="handleRouterBack"> </NavBar>

    <div class="content">
      <div class="title">
        <div class="icon" :style="{ background: infoText.background }">
          <Icon :name="infoText.icon"></Icon>
        </div>
        <p class="fw-600">{{ infoText.name }}</p>
      </div>

      <p class="balance">
        {{ useFormatMoney($$(info, 'tradeAmount') / 100, 2) }}
      </p>

      <p class="font-14 mt-12 text-center">
        <span v-if="info.incomeType === 1">{{ info.oppositeName }}</span>
        <span v-else>
          <span v-if="info.bankName">{{ info.bankName }}</span>
          <span v-if="info.bankCardNumber">（ {{ info.bankCardNumber ? info.bankCardNumber.slice(-4) : '' }} )</span>
        </span>
      </p>

      <ul class="bdt mt-24">
        <li class="font-14 d-flex justify-space-between mt-24">
          <p class="text_muted">到账时间</p>
          <p class="text_main">
            {{ formatToDateTime($$(info, 'tradeTime'), 'yyyy-MM-dd hh:mm') }}
          </p>
        </li>
        <li class="font-14 d-flex justify-space-between mt-24">
          <p class="text_muted">交易流水号</p>
          <p class="text_main">{{ $$(info, 'tradeNo') }}</p>
        </li>
      </ul>
    </div>
  </Page>
</template>
<script>
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import { useRoute, useRouter } from '@/utils/compsitionHack';

import NavBar from '@/components/NavBar/index';
import { Button } from 'vant';
import useFormatMoney from '@/hook/useFormatMoney';
import { apiGetAccountFundFlow } from '@/api/balance';
import { InComeEnum } from '../constants';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { Toast } from 'vant';

export default defineComponent({
  components: {
    NavBar,
    Button
  },
  setup() {
    const { query } = useRoute();
    const router = useRouter();
    onBeforeMount(async () => {
      Toast.loading({
        duration: 0,
        message: '数据加载中...'
      });
      try {
        const res = await apiGetAccountFundFlow({ accountFundFlowId: query.id });
        if (res.data) {
          info.value = res.data;

          if (info.value.type === 8) {
            infoText.value = InComeEnum[8];
          } else {
            infoText.value = InComeEnum[res.data.incomeType];
          }
        }
      } finally {
        Toast.clear();
      }
    });

    const info = ref({});
    const infoText = ref({});
    const handleRouterBack = () => {
      router.push('/');
    };

    return {
      info,
      infoText,
      useFormatMoney,
      formatToDateTime,
      handleRouterBack
    };
  }
});
</script>
<style lang="less" scoped>
.content {
  border: 0.16rem solid #f5f5f5;
  padding: 0.2rem 0.16rem 0.16rem;
  background: #fff;
  border-radius: 0.04rem;
  height: calc(100vh - 0.44rem);
  .title {
    display: flex;
    .icon {
      height: 0.32rem;
      width: 0.32rem;
      background: rgba(64, 152, 255, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-out {
      background: #fa8c16;
    }
    p {
      padding-left: 0.08rem;
      height: 0.32rem;
      font-size: 0.14rem;
      font-weight: 400;
      text-align: left;
      color: #1d1d1d;
      line-height: 0.34rem;
    }
  }
  .balance {
    font-size: 0.24rem;
    font-family: DINPro, DINPro-Medium;
    font-weight: 600;
    text-align: center;
    color: #1d1d1d;
    line-height: 0.24rem;
    letter-spacing: 0px;
    margin-top: 0.24rem;
  }
}
</style>
